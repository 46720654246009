var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import classNames from 'classnames';
import Notification from 'rc-notification';
import * as React from 'react';
import ConfigProvider, { globalConfig } from '../config-provider';
import createUseNotification from './hooks/useNotification';
const notificationInstance = {};
let defaultDuration = 4.5;
let defaultTop = 24;
let defaultBottom = 24;
let defaultPrefixCls = '';
let defaultPlacement = 'topRight';
let defaultGetContainer;
let defaultCloseIcon;
let rtl = false;
let maxCount;
function setNotificationConfig(options) {
    const { duration, placement, bottom, top, getContainer, closeIcon, prefixCls } = options;
    if (prefixCls !== undefined) {
        defaultPrefixCls = prefixCls;
    }
    if (duration !== undefined) {
        defaultDuration = duration;
    }
    if (placement !== undefined) {
        defaultPlacement = placement;
    }
    else if (options.rtl) {
        defaultPlacement = 'topLeft';
    }
    if (bottom !== undefined) {
        defaultBottom = bottom;
    }
    if (top !== undefined) {
        defaultTop = top;
    }
    if (getContainer !== undefined) {
        defaultGetContainer = getContainer;
    }
    if (closeIcon !== undefined) {
        defaultCloseIcon = closeIcon;
    }
    if (options.rtl !== undefined) {
        rtl = options.rtl;
    }
    if (options.maxCount !== undefined) {
        maxCount = options.maxCount;
    }
}
function getPlacementStyle(placement, top = defaultTop, bottom = defaultBottom) {
    let style;
    switch (placement) {
        case 'top':
            style = {
                left: '50%',
                transform: 'translateX(-50%)',
                right: 'auto',
                top,
                bottom: 'auto',
            };
            break;
        case 'topLeft':
            style = {
                left: 0,
                top,
                bottom: 'auto',
            };
            break;
        case 'topRight':
            style = {
                right: 0,
                top,
                bottom: 'auto',
            };
            break;
        case 'bottom':
            style = {
                left: '50%',
                transform: 'translateX(-50%)',
                right: 'auto',
                top: 'auto',
                bottom,
            };
            break;
        case 'bottomLeft':
            style = {
                left: 0,
                top: 'auto',
                bottom,
            };
            break;
        default:
            style = {
                right: 0,
                top: 'auto',
                bottom,
            };
            break;
    }
    return style;
}
function getNotificationInstance(args, callback) {
    const { placement = defaultPlacement, top, bottom, getContainer = defaultGetContainer, prefixCls: customizePrefixCls, } = args;
    const { getPrefixCls, getIconPrefixCls } = globalConfig();
    const prefixCls = getPrefixCls('notification', customizePrefixCls || defaultPrefixCls);
    const iconPrefixCls = getIconPrefixCls();
    const cacheKey = `${prefixCls}-${placement}`;
    const cacheInstance = notificationInstance[cacheKey];
    if (cacheInstance) {
        Promise.resolve(cacheInstance).then(instance => {
            callback({ prefixCls: `${prefixCls}-notice`, iconPrefixCls, instance });
        });
        return;
    }
    const notificationClass = classNames(`${prefixCls}-${placement}`, {
        [`${prefixCls}-rtl`]: rtl === true,
    });
    notificationInstance[cacheKey] = new Promise(resolve => {
        Notification.newInstance({
            prefixCls,
            className: notificationClass,
            style: getPlacementStyle(placement, top, bottom),
            getContainer,
            maxCount,
        }, notification => {
            resolve(notification);
            callback({
                prefixCls: `${prefixCls}-notice`,
                iconPrefixCls,
                instance: notification,
            });
        });
    });
}
const typeToIcon = {
    success: CheckCircleOutlined,
    info: InfoCircleOutlined,
    error: CloseCircleOutlined,
    warning: ExclamationCircleOutlined,
};
function getRCNoticeProps(args, prefixCls, iconPrefixCls) {
    const { duration: durationArg, icon, type, description, message, btn, onClose, onClick, key, style, className, closeIcon = defaultCloseIcon, } = args;
    const duration = durationArg === undefined ? defaultDuration : durationArg;
    let iconNode = null;
    if (icon) {
        iconNode = React.createElement("span", { className: `${prefixCls}-icon` }, args.icon);
    }
    else if (type) {
        iconNode = React.createElement(typeToIcon[type] || null, {
            className: `${prefixCls}-icon ${prefixCls}-icon-${type}`,
        });
    }
    const closeIconToRender = (React.createElement("span", { className: `${prefixCls}-close-x` }, closeIcon || React.createElement(CloseOutlined, { className: `${prefixCls}-close-icon` })));
    const autoMarginTag = !description && iconNode ? (React.createElement("span", { className: `${prefixCls}-message-single-line-auto-margin` })) : null;
    return {
        content: (React.createElement(ConfigProvider, { iconPrefixCls: iconPrefixCls },
            React.createElement("div", { className: iconNode ? `${prefixCls}-with-icon` : '', role: "alert" },
                iconNode,
                React.createElement("div", { className: `${prefixCls}-message` },
                    autoMarginTag,
                    message),
                React.createElement("div", { className: `${prefixCls}-description` }, description),
                btn ? React.createElement("span", { className: `${prefixCls}-btn` }, btn) : null))),
        duration,
        closable: true,
        closeIcon: closeIconToRender,
        onClose,
        onClick,
        key,
        style: style || {},
        className: classNames(className, {
            [`${prefixCls}-${type}`]: !!type,
        }),
    };
}
function notice(args) {
    getNotificationInstance(args, ({ prefixCls, iconPrefixCls, instance }) => {
        instance.notice(getRCNoticeProps(args, prefixCls, iconPrefixCls));
    });
}
const api = {
    open: notice,
    close(key) {
        Object.keys(notificationInstance).forEach(cacheKey => Promise.resolve(notificationInstance[cacheKey]).then(instance => {
            instance.removeNotice(key);
        }));
    },
    config: setNotificationConfig,
    destroy() {
        Object.keys(notificationInstance).forEach(cacheKey => {
            Promise.resolve(notificationInstance[cacheKey]).then(instance => {
                instance.destroy();
            });
            delete notificationInstance[cacheKey]; // lgtm[js/missing-await]
        });
    },
};
['success', 'info', 'warning', 'error'].forEach(type => {
    api[type] = (args) => api.open(Object.assign(Object.assign({}, args), { type }));
});
api.warn = api.warning;
api.useNotification = createUseNotification(getNotificationInstance, getRCNoticeProps);
/** @internal test Only function. Not work on production */
export const getInstance = (cacheKey) => __awaiter(void 0, void 0, void 0, function* () { return process.env.NODE_ENV === 'test' ? notificationInstance[cacheKey] : null; });
export default api;
