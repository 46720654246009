var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import classNames from 'classnames';
import RcInput from 'rc-input';
import { composeRef } from 'rc-util/lib/ref';
import React, { forwardRef, useContext, useEffect, useRef } from 'react';
import { ConfigContext } from '../config-provider';
import DisabledContext from '../config-provider/DisabledContext';
import SizeContext from '../config-provider/SizeContext';
import { FormItemInputContext, NoFormStyle } from '../form/context';
import { getMergedStatus, getStatusClassNames } from '../_util/statusUtils';
import warning from '../_util/warning';
import { hasPrefixSuffix } from './utils';
export function fixControlledValue(value) {
    if (typeof value === 'undefined' || value === null) {
        return '';
    }
    return String(value);
}
export function resolveOnChange(target, e, onChange, targetValue) {
    if (!onChange) {
        return;
    }
    let event = e;
    if (e.type === 'click') {
        // Clone a new target for event.
        // Avoid the following usage, the setQuery method gets the original value.
        //
        // const [query, setQuery] = React.useState('');
        // <Input
        //   allowClear
        //   value={query}
        //   onChange={(e)=> {
        //     setQuery((prevStatus) => e.target.value);
        //   }}
        // />
        const currentTarget = target.cloneNode(true);
        // click clear icon
        event = Object.create(e, {
            target: { value: currentTarget },
            currentTarget: { value: currentTarget },
        });
        currentTarget.value = '';
        onChange(event);
        return;
    }
    // Trigger by composition event, this means we need force change the input value
    if (targetValue !== undefined) {
        event = Object.create(e, {
            target: { value: target },
            currentTarget: { value: target },
        });
        target.value = targetValue;
        onChange(event);
        return;
    }
    onChange(event);
}
export function triggerFocus(element, option) {
    if (!element) {
        return;
    }
    element.focus(option);
    // Selection content
    const { cursor } = option || {};
    if (cursor) {
        const len = element.value.length;
        switch (cursor) {
            case 'start':
                element.setSelectionRange(0, 0);
                break;
            case 'end':
                element.setSelectionRange(len, len);
                break;
            default:
                element.setSelectionRange(0, len);
                break;
        }
    }
}
const Input = forwardRef((props, ref) => {
    const { prefixCls: customizePrefixCls, bordered = true, status: customStatus, size: customSize, disabled: customDisabled, onBlur, onFocus, suffix, allowClear, addonAfter, addonBefore, onChange } = props, rest = __rest(props, ["prefixCls", "bordered", "status", "size", "disabled", "onBlur", "onFocus", "suffix", "allowClear", "addonAfter", "addonBefore", "onChange"]);
    const { getPrefixCls, direction, input } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('input', customizePrefixCls);
    const inputRef = useRef(null);
    // ===================== Size =====================
    const size = React.useContext(SizeContext);
    const mergedSize = customSize || size;
    // ===================== Disabled =====================
    const disabled = React.useContext(DisabledContext);
    const mergedDisabled = customDisabled || disabled;
    // ===================== Status =====================
    const { status: contextStatus, hasFeedback, feedbackIcon } = useContext(FormItemInputContext);
    const mergedStatus = getMergedStatus(contextStatus, customStatus);
    // ===================== Focus warning =====================
    const inputHasPrefixSuffix = hasPrefixSuffix(props) || !!hasFeedback;
    const prevHasPrefixSuffix = useRef(inputHasPrefixSuffix);
    useEffect(() => {
        var _a;
        if (inputHasPrefixSuffix && !prevHasPrefixSuffix.current) {
            warning(document.activeElement === ((_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.input), 'Input', `When Input is focused, dynamic add or remove prefix / suffix will make it lose focus caused by dom structure change. Read more: https://ant.design/components/input/#FAQ`);
        }
        prevHasPrefixSuffix.current = inputHasPrefixSuffix;
    }, [inputHasPrefixSuffix]);
    // ===================== Remove Password value =====================
    const removePasswordTimeoutRef = useRef([]);
    const removePasswordTimeout = () => {
        removePasswordTimeoutRef.current.push(window.setTimeout(() => {
            var _a, _b, _c, _d;
            if (((_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.input) &&
                ((_b = inputRef.current) === null || _b === void 0 ? void 0 : _b.input.getAttribute('type')) === 'password' &&
                ((_c = inputRef.current) === null || _c === void 0 ? void 0 : _c.input.hasAttribute('value'))) {
                (_d = inputRef.current) === null || _d === void 0 ? void 0 : _d.input.removeAttribute('value');
            }
        }));
    };
    useEffect(() => {
        removePasswordTimeout();
        return () => removePasswordTimeoutRef.current.forEach(item => window.clearTimeout(item));
    }, []);
    const handleBlur = (e) => {
        removePasswordTimeout();
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
    };
    const handleFocus = (e) => {
        removePasswordTimeout();
        onFocus === null || onFocus === void 0 ? void 0 : onFocus(e);
    };
    const handleChange = (e) => {
        removePasswordTimeout();
        onChange === null || onChange === void 0 ? void 0 : onChange(e);
    };
    const suffixNode = (hasFeedback || suffix) && (React.createElement(React.Fragment, null,
        suffix,
        hasFeedback && feedbackIcon));
    // Allow clear
    let mergedAllowClear;
    if (typeof allowClear === 'object' && (allowClear === null || allowClear === void 0 ? void 0 : allowClear.clearIcon)) {
        mergedAllowClear = allowClear;
    }
    else if (allowClear) {
        mergedAllowClear = { clearIcon: React.createElement(CloseCircleFilled, null) };
    }
    return (React.createElement(RcInput, Object.assign({ ref: composeRef(ref, inputRef), prefixCls: prefixCls, autoComplete: input === null || input === void 0 ? void 0 : input.autoComplete }, rest, { disabled: mergedDisabled || undefined, onBlur: handleBlur, onFocus: handleFocus, suffix: suffixNode, allowClear: mergedAllowClear, onChange: handleChange, addonAfter: addonAfter && (React.createElement(NoFormStyle, { override: true, status: true }, addonAfter)), addonBefore: addonBefore && (React.createElement(NoFormStyle, { override: true, status: true }, addonBefore)), inputClassName: classNames({
            [`${prefixCls}-sm`]: mergedSize === 'small',
            [`${prefixCls}-lg`]: mergedSize === 'large',
            [`${prefixCls}-rtl`]: direction === 'rtl',
            [`${prefixCls}-borderless`]: !bordered,
        }, !inputHasPrefixSuffix && getStatusClassNames(prefixCls, mergedStatus)), affixWrapperClassName: classNames({
            [`${prefixCls}-affix-wrapper-sm`]: mergedSize === 'small',
            [`${prefixCls}-affix-wrapper-lg`]: mergedSize === 'large',
            [`${prefixCls}-affix-wrapper-rtl`]: direction === 'rtl',
            [`${prefixCls}-affix-wrapper-borderless`]: !bordered,
        }, getStatusClassNames(`${prefixCls}-affix-wrapper`, mergedStatus, hasFeedback)), wrapperClassName: classNames({
            [`${prefixCls}-group-rtl`]: direction === 'rtl',
        }), groupClassName: classNames({
            [`${prefixCls}-group-wrapper-sm`]: mergedSize === 'small',
            [`${prefixCls}-group-wrapper-lg`]: mergedSize === 'large',
            [`${prefixCls}-group-wrapper-rtl`]: direction === 'rtl',
        }, getStatusClassNames(`${prefixCls}-group-wrapper`, mergedStatus, hasFeedback)) })));
});
export default Input;
