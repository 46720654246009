import * as React from 'react';
import Button from '../button';
import { convertLegacyProps } from '../button/button';
import ActionButton from '../_util/ActionButton';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import defaultLocale from '../locale/default';
import { getRenderPropValue } from '../_util/getRenderPropValue';
import { ConfigContext } from '../config-provider';
export const Overlay = props => {
    const { prefixCls, okButtonProps, cancelButtonProps, title, cancelText, okText, okType, icon, showCancel = true, close, onConfirm, onCancel, } = props;
    const { getPrefixCls } = React.useContext(ConfigContext);
    return (React.createElement(LocaleReceiver, { componentName: "Popconfirm", defaultLocale: defaultLocale.Popconfirm }, contextLocale => (React.createElement("div", { className: `${prefixCls}-inner-content` },
        React.createElement("div", { className: `${prefixCls}-message` },
            icon,
            React.createElement("div", { className: `${prefixCls}-message-title` }, getRenderPropValue(title))),
        React.createElement("div", { className: `${prefixCls}-buttons` },
            showCancel && (React.createElement(Button, Object.assign({ onClick: onCancel, size: "small" }, cancelButtonProps), cancelText !== null && cancelText !== void 0 ? cancelText : contextLocale.cancelText)),
            React.createElement(ActionButton, { buttonProps: Object.assign(Object.assign({ size: 'small' }, convertLegacyProps(okType)), okButtonProps), actionFn: onConfirm, close: close, prefixCls: getPrefixCls('btn'), quitOnNullishReturnValue: true, emitEvent: true }, okText !== null && okText !== void 0 ? okText : contextLocale.okText))))));
};
