import RightOutlined from '@ant-design/icons/RightOutlined';
import classNames from 'classnames';
import RcDropdown from 'rc-dropdown';
import useEvent from 'rc-util/lib/hooks/useEvent';
import useMergedState from 'rc-util/lib/hooks/useMergedState';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import { OverrideProvider } from '../menu/OverrideContext';
import getPlacements from '../_util/placements';
import { cloneElement } from '../_util/reactNode';
import { tuple } from '../_util/type';
import warning from '../_util/warning';
import DropdownButton from './dropdown-button';
const Placements = tuple('topLeft', 'topCenter', 'topRight', 'bottomLeft', 'bottomCenter', 'bottomRight', 'top', 'bottom');
const Dropdown = props => {
    const { getPopupContainer: getContextPopupContainer, getPrefixCls, direction, } = React.useContext(ConfigContext);
    // Warning for deprecated usage
    if (process.env.NODE_ENV !== 'production') {
        [
            ['visible', 'open'],
            ['onVisibleChange', 'onOpenChange'],
        ].forEach(([deprecatedName, newName]) => {
            warning(!(deprecatedName in props), 'Dropdown', `\`${deprecatedName}\` is deprecated which will be removed in next major version, please use \`${newName}\` instead.`);
        });
    }
    const getTransitionName = () => {
        const rootPrefixCls = getPrefixCls();
        const { placement = '', transitionName } = props;
        if (transitionName !== undefined) {
            return transitionName;
        }
        if (placement.includes('top')) {
            return `${rootPrefixCls}-slide-down`;
        }
        return `${rootPrefixCls}-slide-up`;
    };
    const getPlacement = () => {
        const { placement } = props;
        if (!placement) {
            return direction === 'rtl' ? 'bottomRight' : 'bottomLeft';
        }
        if (placement.includes('Center')) {
            const newPlacement = placement.slice(0, placement.indexOf('Center'));
            warning(!placement.includes('Center'), 'Dropdown', `You are using '${placement}' placement in Dropdown, which is deprecated. Try to use '${newPlacement}' instead.`);
            return newPlacement;
        }
        return placement;
    };
    const { arrow, prefixCls: customizePrefixCls, children, trigger, disabled, getPopupContainer, overlayClassName, visible, open, onVisibleChange, onOpenChange, mouseEnterDelay = 0.15, mouseLeaveDelay = 0.1, } = props;
    const prefixCls = getPrefixCls('dropdown', customizePrefixCls);
    const child = React.Children.only(children);
    const dropdownTrigger = cloneElement(child, {
        className: classNames(`${prefixCls}-trigger`, {
            [`${prefixCls}-rtl`]: direction === 'rtl',
        }, child.props.className),
        disabled,
    });
    const triggerActions = disabled ? [] : trigger;
    let alignPoint;
    if (triggerActions && triggerActions.includes('contextMenu')) {
        alignPoint = true;
    }
    // =========================== Visible ============================
    const [mergedOpen, setOpen] = useMergedState(false, {
        value: open !== undefined ? open : visible,
    });
    const onInnerOpenChange = useEvent((nextOpen) => {
        onVisibleChange === null || onVisibleChange === void 0 ? void 0 : onVisibleChange(nextOpen);
        onOpenChange === null || onOpenChange === void 0 ? void 0 : onOpenChange(nextOpen);
        setOpen(nextOpen);
    });
    // =========================== Overlay ============================
    const overlayClassNameCustomized = classNames(overlayClassName, {
        [`${prefixCls}-rtl`]: direction === 'rtl',
    });
    const builtinPlacements = getPlacements({
        arrowPointAtCenter: typeof arrow === 'object' && arrow.pointAtCenter,
        autoAdjustOverflow: true,
    });
    const onMenuClick = React.useCallback(() => {
        setOpen(false);
    }, []);
    const renderOverlay = () => {
        // rc-dropdown already can process the function of overlay, but we have check logic here.
        // So we need render the element to check and pass back to rc-dropdown.
        const { overlay } = props;
        let overlayNode;
        if (typeof overlay === 'function') {
            overlayNode = overlay();
        }
        else {
            overlayNode = overlay;
        }
        overlayNode = React.Children.only(typeof overlayNode === 'string' ? React.createElement("span", null, overlayNode) : overlayNode);
        return (React.createElement(OverrideProvider, { prefixCls: `${prefixCls}-menu`, expandIcon: React.createElement("span", { className: `${prefixCls}-menu-submenu-arrow` },
                React.createElement(RightOutlined, { className: `${prefixCls}-menu-submenu-arrow-icon` })), mode: "vertical", selectable: false, onClick: onMenuClick, validator: ({ mode }) => {
                // Warning if use other mode
                warning(!mode || mode === 'vertical', 'Dropdown', `mode="${mode}" is not supported for Dropdown's Menu.`);
            } }, overlayNode));
    };
    // ============================ Render ============================
    return (React.createElement(RcDropdown, Object.assign({ alignPoint: alignPoint }, props, { mouseEnterDelay: mouseEnterDelay, mouseLeaveDelay: mouseLeaveDelay, visible: mergedOpen, builtinPlacements: builtinPlacements, arrow: !!arrow, overlayClassName: overlayClassNameCustomized, prefixCls: prefixCls, getPopupContainer: getPopupContainer || getContextPopupContainer, transitionName: getTransitionName(), trigger: triggerActions, overlay: renderOverlay, placement: getPlacement(), onVisibleChange: onInnerOpenChange }), dropdownTrigger));
};
Dropdown.Button = DropdownButton;
export default Dropdown;
