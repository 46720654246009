import FilterFilled from '@ant-design/icons/FilterFilled';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import * as React from 'react';
import { flattenKeys } from '.';
import Button from '../../../button';
import Checkbox from '../../../checkbox';
import { ConfigContext } from '../../../config-provider/context';
import Dropdown from '../../../dropdown';
import Empty from '../../../empty';
import Menu from '../../../menu';
import { OverrideProvider } from '../../../menu/OverrideContext';
import Radio from '../../../radio';
import Tree from '../../../tree';
import useSyncState from '../../../_util/hooks/useSyncState';
import FilterSearch from './FilterSearch';
import FilterDropdownMenuWrapper from './FilterWrapper';
function hasSubMenu(filters) {
    return filters.some(({ children }) => children);
}
function searchValueMatched(searchValue, text) {
    if (typeof text === 'string' || typeof text === 'number') {
        return text === null || text === void 0 ? void 0 : text.toString().toLowerCase().includes(searchValue.trim().toLowerCase());
    }
    return false;
}
function renderFilterItems({ filters, prefixCls, filteredKeys, filterMultiple, searchValue, filterSearch, }) {
    return filters.map((filter, index) => {
        const key = String(filter.value);
        if (filter.children) {
            return {
                key: key || index,
                label: filter.text,
                popupClassName: `${prefixCls}-dropdown-submenu`,
                children: renderFilterItems({
                    filters: filter.children,
                    prefixCls,
                    filteredKeys,
                    filterMultiple,
                    searchValue,
                    filterSearch,
                }),
            };
        }
        const Component = filterMultiple ? Checkbox : Radio;
        const item = {
            key: filter.value !== undefined ? key : index,
            label: (React.createElement(React.Fragment, null,
                React.createElement(Component, { checked: filteredKeys.includes(key) }),
                React.createElement("span", null, filter.text))),
        };
        if (searchValue.trim()) {
            if (typeof filterSearch === 'function') {
                return filterSearch(searchValue, filter) ? item : null;
            }
            return searchValueMatched(searchValue, filter.text) ? item : null;
        }
        return item;
    });
}
function FilterDropdown(props) {
    var _a;
    const { tablePrefixCls, prefixCls, column, dropdownPrefixCls, columnKey, filterMultiple, filterMode = 'menu', filterSearch = false, filterState, triggerFilter, locale, children, getPopupContainer, } = props;
    const { filterDropdownOpen, onFilterDropdownOpenChange, filterDropdownVisible, onFilterDropdownVisibleChange, filterResetToDefaultFilteredValue, defaultFilteredValue, } = column;
    const [visible, setVisible] = React.useState(false);
    const filtered = !!(filterState &&
        (((_a = filterState.filteredKeys) === null || _a === void 0 ? void 0 : _a.length) || filterState.forceFiltered));
    const triggerVisible = (newVisible) => {
        setVisible(newVisible);
        onFilterDropdownOpenChange === null || onFilterDropdownOpenChange === void 0 ? void 0 : onFilterDropdownOpenChange(newVisible);
        onFilterDropdownVisibleChange === null || onFilterDropdownVisibleChange === void 0 ? void 0 : onFilterDropdownVisibleChange(newVisible);
    };
    let mergedVisible;
    if (typeof filterDropdownOpen === 'boolean') {
        mergedVisible = filterDropdownOpen;
    }
    else {
        mergedVisible = typeof filterDropdownVisible === 'boolean' ? filterDropdownVisible : visible;
    }
    // ===================== Select Keys =====================
    const propFilteredKeys = filterState === null || filterState === void 0 ? void 0 : filterState.filteredKeys;
    const [getFilteredKeysSync, setFilteredKeysSync] = useSyncState(propFilteredKeys || []);
    const onSelectKeys = ({ selectedKeys }) => {
        setFilteredKeysSync(selectedKeys);
    };
    const onCheck = (keys, { node, checked }) => {
        if (!filterMultiple) {
            onSelectKeys({ selectedKeys: checked && node.key ? [node.key] : [] });
        }
        else {
            onSelectKeys({ selectedKeys: keys });
        }
    };
    React.useEffect(() => {
        if (!visible) {
            return;
        }
        onSelectKeys({ selectedKeys: propFilteredKeys || [] });
    }, [propFilteredKeys]);
    // ====================== Open Keys ======================
    const [openKeys, setOpenKeys] = React.useState([]);
    const onOpenChange = (keys) => {
        setOpenKeys(keys);
    };
    // search in tree mode column filter
    const [searchValue, setSearchValue] = React.useState('');
    const onSearch = (e) => {
        const { value } = e.target;
        setSearchValue(value);
    };
    // clear search value after close filter dropdown
    React.useEffect(() => {
        if (!visible) {
            setSearchValue('');
        }
    }, [visible]);
    // ======================= Submit ========================
    const internalTriggerFilter = (keys) => {
        const mergedKeys = keys && keys.length ? keys : null;
        if (mergedKeys === null && (!filterState || !filterState.filteredKeys)) {
            return null;
        }
        if (isEqual(mergedKeys, filterState === null || filterState === void 0 ? void 0 : filterState.filteredKeys)) {
            return null;
        }
        triggerFilter({
            column,
            key: columnKey,
            filteredKeys: mergedKeys,
        });
    };
    const onConfirm = () => {
        triggerVisible(false);
        internalTriggerFilter(getFilteredKeysSync());
    };
    const onReset = ({ confirm, closeDropdown } = { confirm: false, closeDropdown: false }) => {
        if (confirm) {
            internalTriggerFilter([]);
        }
        if (closeDropdown) {
            triggerVisible(false);
        }
        setSearchValue('');
        if (filterResetToDefaultFilteredValue) {
            setFilteredKeysSync((defaultFilteredValue || []).map(key => String(key)));
        }
        else {
            setFilteredKeysSync([]);
        }
    };
    const doFilter = ({ closeDropdown } = { closeDropdown: true }) => {
        if (closeDropdown) {
            triggerVisible(false);
        }
        internalTriggerFilter(getFilteredKeysSync());
    };
    const onVisibleChange = (newVisible) => {
        if (newVisible && propFilteredKeys !== undefined) {
            // Sync filteredKeys on appear in controlled mode (propFilteredKeys !== undefiend)
            setFilteredKeysSync(propFilteredKeys || []);
        }
        triggerVisible(newVisible);
        // Default will filter when closed
        if (!newVisible && !column.filterDropdown) {
            onConfirm();
        }
    };
    // ======================== Style ========================
    const dropdownMenuClass = classNames({
        [`${dropdownPrefixCls}-menu-without-submenu`]: !hasSubMenu(column.filters || []),
    });
    const onCheckAll = (e) => {
        if (e.target.checked) {
            const allFilterKeys = flattenKeys(column === null || column === void 0 ? void 0 : column.filters).map(key => String(key));
            setFilteredKeysSync(allFilterKeys);
        }
        else {
            setFilteredKeysSync([]);
        }
    };
    const getTreeData = ({ filters }) => (filters || []).map((filter, index) => {
        const key = String(filter.value);
        const item = {
            title: filter.text,
            key: filter.value !== undefined ? key : index,
        };
        if (filter.children) {
            item.children = getTreeData({ filters: filter.children });
        }
        return item;
    });
    const getFilterData = (node) => {
        var _a;
        return (Object.assign(Object.assign({}, node), { text: node.title, value: node.key, children: ((_a = node.children) === null || _a === void 0 ? void 0 : _a.map(item => getFilterData(item))) || [] }));
    };
    let dropdownContent;
    if (typeof column.filterDropdown === 'function') {
        dropdownContent = column.filterDropdown({
            prefixCls: `${dropdownPrefixCls}-custom`,
            setSelectedKeys: (selectedKeys) => onSelectKeys({ selectedKeys }),
            selectedKeys: getFilteredKeysSync(),
            confirm: doFilter,
            clearFilters: onReset,
            filters: column.filters,
            visible: mergedVisible,
        });
    }
    else if (column.filterDropdown) {
        dropdownContent = column.filterDropdown;
    }
    else {
        const selectedKeys = (getFilteredKeysSync() || []);
        const getFilterComponent = () => {
            if ((column.filters || []).length === 0) {
                return (React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: locale.filterEmptyText, imageStyle: {
                        height: 24,
                    }, style: {
                        margin: 0,
                        padding: '16px 0',
                    } }));
            }
            if (filterMode === 'tree') {
                return (React.createElement(React.Fragment, null,
                    React.createElement(FilterSearch, { filterSearch: filterSearch, value: searchValue, onChange: onSearch, tablePrefixCls: tablePrefixCls, locale: locale }),
                    React.createElement("div", { className: `${tablePrefixCls}-filter-dropdown-tree` },
                        filterMultiple ? (React.createElement(Checkbox, { checked: selectedKeys.length === flattenKeys(column.filters).length, indeterminate: selectedKeys.length > 0 &&
                                selectedKeys.length < flattenKeys(column.filters).length, className: `${tablePrefixCls}-filter-dropdown-checkall`, onChange: onCheckAll }, locale.filterCheckall)) : null,
                        React.createElement(Tree, { checkable: true, selectable: false, blockNode: true, multiple: filterMultiple, checkStrictly: !filterMultiple, className: `${dropdownPrefixCls}-menu`, onCheck: onCheck, checkedKeys: selectedKeys, selectedKeys: selectedKeys, showIcon: false, treeData: getTreeData({ filters: column.filters }), autoExpandParent: true, defaultExpandAll: true, filterTreeNode: searchValue.trim()
                                ? node => {
                                    if (typeof filterSearch === 'function') {
                                        return filterSearch(searchValue, getFilterData(node));
                                    }
                                    return searchValueMatched(searchValue, node.title);
                                }
                                : undefined }))));
            }
            return (React.createElement(React.Fragment, null,
                React.createElement(FilterSearch, { filterSearch: filterSearch, value: searchValue, onChange: onSearch, tablePrefixCls: tablePrefixCls, locale: locale }),
                React.createElement(Menu, { selectable: true, multiple: filterMultiple, prefixCls: `${dropdownPrefixCls}-menu`, className: dropdownMenuClass, onSelect: onSelectKeys, onDeselect: onSelectKeys, selectedKeys: selectedKeys, getPopupContainer: getPopupContainer, openKeys: openKeys, onOpenChange: onOpenChange, items: renderFilterItems({
                        filters: column.filters || [],
                        filterSearch,
                        prefixCls,
                        filteredKeys: getFilteredKeysSync(),
                        filterMultiple,
                        searchValue,
                    }) })));
        };
        const getResetDisabled = () => {
            if (filterResetToDefaultFilteredValue) {
                return isEqual((defaultFilteredValue || []).map(key => String(key)), selectedKeys);
            }
            return selectedKeys.length === 0;
        };
        dropdownContent = (React.createElement(React.Fragment, null,
            getFilterComponent(),
            React.createElement("div", { className: `${prefixCls}-dropdown-btns` },
                React.createElement(Button, { type: "link", size: "small", disabled: getResetDisabled(), onClick: () => onReset() }, locale.filterReset),
                React.createElement(Button, { type: "primary", size: "small", onClick: onConfirm }, locale.filterConfirm))));
    }
    // We should not block customize Menu with additional props
    if (column.filterDropdown) {
        dropdownContent = React.createElement(OverrideProvider, { selectable: undefined }, dropdownContent);
    }
    const menu = (React.createElement(FilterDropdownMenuWrapper, { className: `${prefixCls}-dropdown` }, dropdownContent));
    let filterIcon;
    if (typeof column.filterIcon === 'function') {
        filterIcon = column.filterIcon(filtered);
    }
    else if (column.filterIcon) {
        filterIcon = column.filterIcon;
    }
    else {
        filterIcon = React.createElement(FilterFilled, null);
    }
    const { direction } = React.useContext(ConfigContext);
    return (React.createElement("div", { className: `${prefixCls}-column` },
        React.createElement("span", { className: `${tablePrefixCls}-column-title` }, children),
        React.createElement(Dropdown, { overlay: menu, trigger: ['click'], open: mergedVisible, onOpenChange: onVisibleChange, getPopupContainer: getPopupContainer, placement: direction === 'rtl' ? 'bottomLeft' : 'bottomRight' },
            React.createElement("span", { role: "button", tabIndex: -1, className: classNames(`${prefixCls}-trigger`, {
                    active: filtered,
                }), onClick: e => {
                    e.stopPropagation();
                } }, filterIcon))));
}
export default FilterDropdown;
