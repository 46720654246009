var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import EyeInvisibleOutlined from '@ant-design/icons/EyeInvisibleOutlined';
import EyeOutlined from '@ant-design/icons/EyeOutlined';
import classNames from 'classnames';
import omit from 'rc-util/lib/omit';
import * as React from 'react';
import { useState } from 'react';
import { ConfigConsumer } from '../config-provider';
import Input from './Input';
const defaultIconRender = (visible) => visible ? React.createElement(EyeOutlined, null) : React.createElement(EyeInvisibleOutlined, null);
const ActionMap = {
    click: 'onClick',
    hover: 'onMouseOver',
};
const Password = React.forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false);
    const onVisibleChange = () => {
        const { disabled } = props;
        if (disabled) {
            return;
        }
        setVisible(prevState => !prevState);
    };
    const getIcon = (prefixCls) => {
        const { action = 'click', iconRender = defaultIconRender } = props;
        const iconTrigger = ActionMap[action] || '';
        const icon = iconRender(visible);
        const iconProps = {
            [iconTrigger]: onVisibleChange,
            className: `${prefixCls}-icon`,
            key: 'passwordIcon',
            onMouseDown: (e) => {
                // Prevent focused state lost
                // https://github.com/ant-design/ant-design/issues/15173
                e.preventDefault();
            },
            onMouseUp: (e) => {
                // Prevent caret position change
                // https://github.com/ant-design/ant-design/issues/23524
                e.preventDefault();
            },
        };
        return React.cloneElement(React.isValidElement(icon) ? icon : React.createElement("span", null, icon), iconProps);
    };
    const renderPassword = ({ getPrefixCls }) => {
        const { className, prefixCls: customizePrefixCls, inputPrefixCls: customizeInputPrefixCls, size, visibilityToggle = true } = props, restProps = __rest(props, ["className", "prefixCls", "inputPrefixCls", "size", "visibilityToggle"]);
        const inputPrefixCls = getPrefixCls('input', customizeInputPrefixCls);
        const prefixCls = getPrefixCls('input-password', customizePrefixCls);
        const suffixIcon = visibilityToggle && getIcon(prefixCls);
        const inputClassName = classNames(prefixCls, className, {
            [`${prefixCls}-${size}`]: !!size,
        });
        const omittedProps = Object.assign(Object.assign({}, omit(restProps, ['suffix', 'iconRender'])), { type: visible ? 'text' : 'password', className: inputClassName, prefixCls: inputPrefixCls, suffix: suffixIcon });
        if (size) {
            omittedProps.size = size;
        }
        return React.createElement(Input, Object.assign({ ref: ref }, omittedProps));
    };
    return React.createElement(ConfigConsumer, null, renderPassword);
});
if (process.env.NODE_ENV !== 'production') {
    Password.displayName = 'Password';
}
export default Password;
