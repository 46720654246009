import classNames from 'classnames';
import memoizeOne from 'memoize-one';
import addEventListener from 'rc-util/lib/Dom/addEventListener';
import * as React from 'react';
import Affix from '../affix';
import { ConfigContext } from '../config-provider';
import getScroll from '../_util/getScroll';
import scrollTo from '../_util/scrollTo';
import AnchorContext from './context';
function getDefaultContainer() {
    return window;
}
function getOffsetTop(element, container) {
    if (!element.getClientRects().length) {
        return 0;
    }
    const rect = element.getBoundingClientRect();
    if (rect.width || rect.height) {
        if (container === window) {
            container = element.ownerDocument.documentElement;
            return rect.top - container.clientTop;
        }
        return rect.top - container.getBoundingClientRect().top;
    }
    return rect.top;
}
const sharpMatcherRegx = /#([\S ]+)$/;
class Anchor extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            activeLink: null,
        };
        this.wrapperRef = React.createRef();
        this.links = [];
        // Context
        this.registerLink = (link) => {
            if (!this.links.includes(link)) {
                this.links.push(link);
            }
        };
        this.unregisterLink = (link) => {
            const index = this.links.indexOf(link);
            if (index !== -1) {
                this.links.splice(index, 1);
            }
        };
        this.getContainer = () => {
            const { getTargetContainer } = this.context;
            const { getContainer } = this.props;
            const getFunc = getContainer || getTargetContainer || getDefaultContainer;
            return getFunc();
        };
        this.handleScrollTo = (link) => {
            const { offsetTop, targetOffset } = this.props;
            this.setCurrentActiveLink(link);
            const container = this.getContainer();
            const scrollTop = getScroll(container, true);
            const sharpLinkMatch = sharpMatcherRegx.exec(link);
            if (!sharpLinkMatch) {
                return;
            }
            const targetElement = document.getElementById(sharpLinkMatch[1]);
            if (!targetElement) {
                return;
            }
            const eleOffsetTop = getOffsetTop(targetElement, container);
            let y = scrollTop + eleOffsetTop;
            y -= targetOffset !== undefined ? targetOffset : offsetTop || 0;
            this.animating = true;
            scrollTo(y, {
                callback: () => {
                    this.animating = false;
                },
                getContainer: this.getContainer,
            });
        };
        this.saveInkNode = (node) => {
            this.inkNode = node;
        };
        this.setCurrentActiveLink = (link, triggerChange = true) => {
            const { activeLink } = this.state;
            const { onChange, getCurrentAnchor } = this.props;
            if (activeLink === link) {
                return;
            }
            // https://github.com/ant-design/ant-design/issues/30584
            this.setState({
                activeLink: typeof getCurrentAnchor === 'function' ? getCurrentAnchor(link) : link,
            });
            if (triggerChange) {
                onChange === null || onChange === void 0 ? void 0 : onChange(link);
            }
        };
        this.handleScroll = () => {
            if (this.animating) {
                return;
            }
            const { offsetTop, bounds, targetOffset } = this.props;
            const currentActiveLink = this.getCurrentAnchor(targetOffset !== undefined ? targetOffset : offsetTop || 0, bounds);
            this.setCurrentActiveLink(currentActiveLink);
        };
        this.updateInk = () => {
            const { prefixCls, wrapperRef } = this;
            const anchorNode = wrapperRef.current;
            const linkNode = anchorNode === null || anchorNode === void 0 ? void 0 : anchorNode.getElementsByClassName(`${prefixCls}-link-title-active`)[0];
            if (linkNode) {
                this.inkNode.style.top = `${linkNode.offsetTop + linkNode.clientHeight / 2 - 4.5}px`;
            }
        };
        this.getMemoizedContextValue = memoizeOne((link, onClickFn) => ({
            registerLink: this.registerLink,
            unregisterLink: this.unregisterLink,
            scrollTo: this.handleScrollTo,
            activeLink: link,
            onClick: onClickFn,
        }));
    }
    componentDidMount() {
        this.scrollContainer = this.getContainer();
        this.scrollEvent = addEventListener(this.scrollContainer, 'scroll', this.handleScroll);
        this.handleScroll();
    }
    componentDidUpdate() {
        const { getCurrentAnchor } = this.props;
        const { activeLink } = this.state;
        if (this.scrollEvent) {
            const currentContainer = this.getContainer();
            if (this.scrollContainer !== currentContainer) {
                this.scrollContainer = currentContainer;
                this.scrollEvent.remove();
                this.scrollEvent = addEventListener(this.scrollContainer, 'scroll', this.handleScroll);
                this.handleScroll();
            }
        }
        if (typeof getCurrentAnchor === 'function') {
            this.setCurrentActiveLink(getCurrentAnchor(activeLink || ''), false);
        }
        this.updateInk();
    }
    componentWillUnmount() {
        if (this.scrollEvent) {
            this.scrollEvent.remove();
        }
    }
    getCurrentAnchor(offsetTop = 0, bounds = 5) {
        const linkSections = [];
        const container = this.getContainer();
        this.links.forEach(link => {
            const sharpLinkMatch = sharpMatcherRegx.exec(link.toString());
            if (!sharpLinkMatch) {
                return;
            }
            const target = document.getElementById(sharpLinkMatch[1]);
            if (target) {
                const top = getOffsetTop(target, container);
                if (top < offsetTop + bounds) {
                    linkSections.push({
                        link,
                        top,
                    });
                }
            }
        });
        if (linkSections.length) {
            const maxSection = linkSections.reduce((prev, curr) => (curr.top > prev.top ? curr : prev));
            return maxSection.link;
        }
        return '';
    }
    render() {
        const { direction } = this.context;
        const { anchorPrefixCls: prefixCls, className = '', style, offsetTop, affix, showInkInFixed, children, onClick, } = this.props;
        const { activeLink } = this.state;
        // To support old version react.
        // Have to add prefixCls on the instance.
        // https://github.com/facebook/react/issues/12397
        this.prefixCls = prefixCls;
        const inkClass = classNames(`${prefixCls}-ink-ball`, {
            visible: activeLink,
        });
        const wrapperClass = classNames(`${prefixCls}-wrapper`, {
            [`${prefixCls}-rtl`]: direction === 'rtl',
        }, className);
        const anchorClass = classNames(prefixCls, {
            [`${prefixCls}-fixed`]: !affix && !showInkInFixed,
        });
        const wrapperStyle = Object.assign({ maxHeight: offsetTop ? `calc(100vh - ${offsetTop}px)` : '100vh' }, style);
        const anchorContent = (React.createElement("div", { ref: this.wrapperRef, className: wrapperClass, style: wrapperStyle },
            React.createElement("div", { className: anchorClass },
                React.createElement("div", { className: `${prefixCls}-ink` },
                    React.createElement("span", { className: inkClass, ref: this.saveInkNode })),
                children)));
        const contextValue = this.getMemoizedContextValue(activeLink, onClick);
        return (React.createElement(AnchorContext.Provider, { value: contextValue }, !affix ? (anchorContent) : (React.createElement(Affix, { offsetTop: offsetTop, target: this.getContainer }, anchorContent))));
    }
}
Anchor.defaultProps = {
    affix: true,
    showInkInFixed: false,
};
Anchor.contextType = ConfigContext;
const AnchorFC = React.forwardRef((props, ref) => {
    const { prefixCls: customizePrefixCls } = props;
    const { getPrefixCls } = React.useContext(ConfigContext);
    const anchorPrefixCls = getPrefixCls('anchor', customizePrefixCls);
    const anchorProps = Object.assign(Object.assign({}, props), { anchorPrefixCls });
    return React.createElement(Anchor, Object.assign({}, anchorProps, { ref: ref }));
});
export default AnchorFC;
