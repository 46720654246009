import VerticalAlignTopOutlined from '@ant-design/icons/VerticalAlignTopOutlined';
import classNames from 'classnames';
import CSSMotion from 'rc-motion';
import addEventListener from 'rc-util/lib/Dom/addEventListener';
import useMergedState from 'rc-util/lib/hooks/useMergedState';
import omit from 'rc-util/lib/omit';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import getScroll from '../_util/getScroll';
import { cloneElement } from '../_util/reactNode';
import scrollTo from '../_util/scrollTo';
import { throttleByAnimationFrame } from '../_util/throttleByAnimationFrame';
const BackTopContent = props => {
    const { prefixCls, rootPrefixCls, children, visible } = props;
    const defaultElement = (React.createElement("div", { className: `${prefixCls}-content` },
        React.createElement("div", { className: `${prefixCls}-icon` },
            React.createElement(VerticalAlignTopOutlined, null))));
    return (React.createElement(CSSMotion, { visible: visible, motionName: `${rootPrefixCls}-fade` }, ({ className: motionClassName }) => cloneElement(children || defaultElement, ({ className }) => ({
        className: classNames(motionClassName, className),
    }))));
};
const BackTop = props => {
    const [visible, setVisible] = useMergedState(false, {
        value: props.visible,
    });
    const ref = React.createRef();
    const scrollEvent = React.useRef();
    const getDefaultTarget = () => ref.current && ref.current.ownerDocument ? ref.current.ownerDocument : window;
    const handleScroll = throttleByAnimationFrame((e) => {
        const { visibilityHeight = 400 } = props;
        const scrollTop = getScroll(e.target, true);
        setVisible(scrollTop > visibilityHeight);
    });
    const bindScrollEvent = () => {
        const { target } = props;
        const getTarget = target || getDefaultTarget;
        const container = getTarget();
        scrollEvent.current = addEventListener(container, 'scroll', (e) => {
            handleScroll(e);
        });
        handleScroll({ target: container });
    };
    React.useEffect(() => {
        bindScrollEvent();
        return () => {
            if (scrollEvent.current) {
                scrollEvent.current.remove();
            }
            handleScroll.cancel();
        };
    }, [props.target]);
    const scrollToTop = (e) => {
        const { onClick, target, duration = 450 } = props;
        scrollTo(0, {
            getContainer: target || getDefaultTarget,
            duration,
        });
        if (typeof onClick === 'function') {
            onClick(e);
        }
    };
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const { prefixCls: customizePrefixCls, className = '' } = props;
    const prefixCls = getPrefixCls('back-top', customizePrefixCls);
    const rootPrefixCls = getPrefixCls();
    const classString = classNames(prefixCls, {
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className);
    // fix https://fb.me/react-unknown-prop
    const divProps = omit(props, [
        'prefixCls',
        'className',
        'children',
        'visibilityHeight',
        'target',
        'visible',
    ]);
    return (React.createElement("div", Object.assign({}, divProps, { className: classString, onClick: scrollToTop, ref: ref }),
        React.createElement(BackTopContent, { prefixCls: prefixCls, rootPrefixCls: rootPrefixCls, visible: visible }, props.children)));
};
export default React.memo(BackTop);
